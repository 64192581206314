
import Vue from "vue";
import ContentContainer from "@/components/content/ContentContainer.vue";
import ModuleDialog from "@/components/base/ModuleDialog.vue";
import SelectTheme from "@/components/base/SelectTheme.vue";
import SelectLogos from "@/components/base/SelectLogos.vue";
import Module from "@/models/moduleArea/Module";
import HomeConfigurationDataModule from "@/store/modules/HomeConfigurationDataModule";
import LanguageGlobalSelectionModule from "@/store/modules/LanguageGlobalSelectionModule";
import { getModule } from "vuex-module-decorators";
import LanguageSpecificData from "@/models/moduleArea/LanguageSpecificData";
import HomePageLayoutData from "@/models/moduleArea/HomePageLayoutData";
import Layout from "@/models/moduleArea/Layout";
import AdminUserGlobalState from "@/store/modules/AdminUserGlobalState";
import i18n from "@/i18n";

export default Vue.extend({
  name: "Home",

  components: {
    ContentContainer,
    ModuleDialog,
    SelectTheme,
    SelectLogos,
  },

  data() {
    return {
      layouts: new Array<Layout>(),
      layout: new Array<Layout>(),
      theme: "",
      leftLogo: "",
      rightLogo: "",
      index: 0,
      colNum: 12,
      editedItem: {},
      editedItemId: -1,
      homeConfigurationData: getModule(
        HomeConfigurationDataModule,
        this.$store
      ),
      languageGlobalSelectionModule: getModule(
        LanguageGlobalSelectionModule,
        this.$store
      ),
      adminUserGlobalState: getModule(AdminUserGlobalState, this.$store),
      showTransitions: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.showTransitions = true;
    }, 100);
    this.index = this.layout.length;
    this.getModuleAreaAndThemeLayoutFromServer();
  },

  computed: {
    isAdminLayout(): boolean {
      if (
        Vue.$keycloak.hasResourceRole("admin") &&
        this.$vuetify.breakpoint.mdAndUp
      ) {
        return this.adminUserGlobalState.isAdminUserActive;
      } else {
        return false;
      }
    },

    color(): any {
      return (item: any) => item.information.color;
    },
    getIndex(): any {
      const locale = this.languageGlobalSelectionModule.selectedLanguage;
      return (item: LanguageSpecificData[]) =>
        item.findIndex((el) => el.language === locale);
    },
  },

  methods: {
    getModuleAreaAndThemeLayoutFromServer(): void {
      let modules = Array<Layout>();
      let theme = "";
      if (Vue.$keycloak.authenticated) {
        this.homeConfigurationData
          .getModuleDataAction()
          .then(() => {
            modules = this.homeConfigurationData.configurationData.modules;
            theme = this.homeConfigurationData.configurationData.theme;

            this.layout = modules;
            this.theme = theme;
          })
          .catch(() => {
            modules = Array<Layout>();
            theme = "";
            this.layout = modules;
            this.theme = theme;
          });
      } else {
        this.layouts = modules;
        this.theme = theme;
      }
    },

    gridItemStyle(item: any): any {
      const imageUrl =
        item.information.asset == "image" ? item.information.image : "";
      return {
        backgroundColor: `${item.information.background} !important`,
        backgroundImage: `url(${imageUrl})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      };
    },

    onSaveTheme(theme: string) {
      this.theme = theme;
    },

    onSaveLogos(leftLogo: string, rightLogo: string) {
      this.leftLogo = leftLogo;
      this.rightLogo = rightLogo;
    },

    breakpointChangedEvent(newBreakpoint: any, newLayout: any) {
      // DO NOT TOUCH THIS, please!! This is an algortihm created in order
      //  to generate a specific layout for the module area on all devices.
      // This layout was decided upon creating the project and it was a requirement.
      if (newBreakpoint === "sm") {
        newLayout.forEach(
          (el: { x: number; i: number; y: number; w: number; h: number }) => {
            el.x =
              el.i === 0
                ? 0
                : newLayout[el.i - 1].x === 0 || el.i === 1 || el.i === 2
                ? 3
                : 0;
            el.y = el.i === 0 ? 0 : newLayout[el.i - 1].y + 5;
            el.w = 3;
            el.h = el.i === 0 ? 10 : 5;
          }
        );
      }
    },

    openModuleDialog() {
      // reset edit details
      this.editedItem = {};
      this.editedItemId = -1;
      // open dialog add new module
      const dialogElem: any = this.$refs.moduleDialog;
      dialogElem.dialog = true;
    },

    openEditModuleDialog(information: Module, i: number) {
      // set edited item details
      this.editedItem = information;
      this.editedItemId = i;
      // open dialog edit module
      const dialogElem: any = this.$refs.moduleDialog;
      dialogElem.dialog = true;
      dialogElem.setItemDetails(this.editedItem);
    },

    onSaveModuleDetails(information: Module) {
      if (Object.keys(this.editedItem).length === 0) {
        this.addItem(information);
      } else {
        this.editItem(information);
      }
    },

    setFormDetails(form: Module, val: Module) {
      form.languageSpecificData = val.languageSpecificData;
      form.link = val.link;
      form.asset = val.asset;
      form.image = val.image;
      form.icon = val.icon;
      form.color = val.color;
      form.background = val.background;
    },

    editItem(information: Module) {
      const index = this.layout.findIndex(
        (elem) => elem.i == this.editedItemId
      );
      this.layout[index].information = information;
    },

    addItem(module: Module) {
      const layoutLength = this.layout.length;
      // Add a new item. It must have a unique key!
      const gridItem = {
        x:
          layoutLength === 0 || this.layout[layoutLength - 1].x === 9
            ? 0
            : this.layout[layoutLength - 1].x + 3,
        y:
          layoutLength === 0
            ? 0
            : this.layout[layoutLength - 1].x === 9
            ? this.layout[layoutLength - 1].y + 5
            : this.layout[layoutLength - 1].y, // puts it at the bottom
        w: 3,
        h: layoutLength === 0 ? 10 : 5,
        moved: false,
        i: layoutLength,
        information: module,
      };

      this.layout.push(gridItem);

      // Increment the counter to ensure key is always unique.
      this.index++;
    },

    removeItem(val: any) {
      const index = this.layout.map((item: any) => item.i).indexOf(val);
      this.layout.splice(index, 1);
    },

    navigateToLink(item: Module) {
      if (!this.isAdminLayout || !Vue.$keycloak.hasResourceRole("admin")) {
        window.open(item.link, "_self");
      }
    },

    onSaveLayout() {
      if (confirm(i18n.t("home_page.confirmSaveLayout").toString())) {
        this.homeConfigurationData.updateModuleDataAction(
          new HomePageLayoutData(
            this.theme,
            this.$store.getters.leftLogoUrl,
            this.$store.getters.rightLogoUrl,
            this.layout
          )
        );
      }
    },
  },
});
